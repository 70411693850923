import { Block } from "@/constants";
import { getBlock } from "@/store/modules/menu";

export default {
  state: () => ({}),
  mutations: {},
  actions: {},
  getters: {
    merchantBlock: (state, getters, rootState) =>
      getBlock(rootState.page, rootState.route.main, Block.MerchantBlock),
    merchantLogoUrl(state, getters) {
      return (
        getters.merchantBlock?.body.find((b) => b.name === "logo_url").value ||
        null
      );
    },
    merchantName(state, getters) {
      return (
        getters.merchantBlock?.body.find((b) => b.name === "name").value || null
      );
    },
  },
};
