import { ActionContext } from "vuex";
import { UAParser } from "ua-parser-js";
import QRCode from "qrcode";

import { REQUEST_ORDER } from "@/store/actions.type";

const INVOICE_STATUS_CHECK_INTERNAL = 1000;

import {
  invoicePaymentData,
  paymentInit,
  paymentCard,
  paymentApplepay,
  paymentSpb,
  merchantPermission,
  InvoicePaymentData,
  PaymentCardData,
  PaymentSbpData,
  InitPaymentData,
  ApplepayNewData,
  ApplepayProgressData,
  paymentGooglepay,
  GooglepayNewData,
  GooglepayProgressData,
  AcquireAlias,
} from "@/api/fns";
import { startApplePay } from "@/utils/apple-pay";
import { startGooglePay } from "@/utils/google-pay";
import {
  getIsReadyToGooglePay,
  // prefetchGooglePaymentData,
} from "@/utils/google-pay";
import { sendOrder } from "@/api/template";
import axios from "axios";
import { processAxiosError } from "@/utils/api";

const isDevelopment = process.env.NODE_ENV === "development";

const googlepayMerchantName = "QUBIQ";
const googlepayMerchantId = "BCR2DN6TZ6EJVGCK";

const gateways = {
  MONETA: "moneta",
  PAY2PRO: undefined,
  PAYLER: "payler",
} as const;

export interface State {
  showSbpQrcode: boolean;
  invoicePaymentData: InvoicePaymentData;
  initPaymentData: {
    account: number;
  };
  confirmData: {
    permission_request_id: null | string;
    merchantId: null | string;
    acceptedReturnUrl: null | string;
    refusedReturnUrl: null | string;
  };
  applepayData: {
    payment_internal_id: null | ApplepayNewData["payment_internal_id"];
  };
  googlepayData: {
    isReadyToPay: boolean;
    payment_internal_id: null | GooglepayNewData["payment_internal_id"];
  };
  sbpData: {
    payment_internal_id: null | string;
    sbp_url: null | string;
  };
  paymentCardData: PaymentCardData;
  email: string;
  error: null | string;
}

export default {
  namespaced: true,
  state: <State>{
    showSbpQrcode: false,
    invoicePaymentData: {
      id: null,
      invoiceNumber: 0,
      merchantId: null,
      acquirerId: null,
      customerType: null,
      totalAmount: 0,
      saleInfo: [],
      internal_id: "",
      status: null,
      date: null,
      merchantName: null,
      merchant_internal_id: 0,
      inn: null,
      error: null,
      listPaymentMethods: null,
      acquirerAlias: null,
      platformAlias: null,
    },
    initPaymentData: {
      account: 0,
    },
    paymentCardData: {
      amount: 0,
      invoice_internal_id: "",
      merchant_internal_id: "",
      email: "",
      payment_internal_id: "",
      payment_external_id: "",
      operation_id: undefined,
      status: "",
    },
    applepayData: {
      payment_internal_id: null,
    },
    googlepayData: {
      isReadyToPay: false,
      payment_internal_id: null,
    },
    sbpData: {
      payment_internal_id: null,
      sbp_url: null,
    },
    merchantPermissionData: {
      result: null,
    },
    confirmData: {
      permission_request_id: null,
      merchantId: null,
      acceptedReturnUrl: null,
      refusedReturnUrl: null,
    },
    email: isDevelopment ? "aa@bb.cc" : null,
    error: null,
  },
  getters: {
    payResultData: (state: State) => state.invoicePaymentData,
    isCardPaymentAvailable: (state: State) =>
      !Array.isArray(state.invoicePaymentData.listPaymentMethods) ||
      state.invoicePaymentData.listPaymentMethods.includes("card"),
    isSbpPaymentAvailable: (state: State) =>
      !Array.isArray(state.invoicePaymentData.listPaymentMethods) ||
      state.invoicePaymentData.listPaymentMethods.includes("sbp"),
    isApplepayPaymentAvailable: (state: State) =>
      !Array.isArray(state.invoicePaymentData.listPaymentMethods) ||
      state.invoicePaymentData.listPaymentMethods.includes("applepay"),
    isGooglepayPaymentAvailable: (state: State) =>
      !Array.isArray(state.invoicePaymentData.listPaymentMethods) ||
      state.invoicePaymentData.listPaymentMethods.includes("googlepay"),
    /**
     * pay2pro, payler
     */
    pay2proPaymentUrl: (state: State) =>
      state.paymentCardData?.paymentUrl || null,
    isDesktop: () => {
      return !new UAParser().getDevice().type;
    },
    showSbpQrcode: (state: State) => state.showSbpQrcode,
  },
  actions: {
    async requestInvoicePaymentData(
      { commit, dispatch }: ActionContext<State, any>,
      { invoiceId }: { invoiceId: string }
    ) {
      commit("setLoading", true, { root: true });
      try {
        const paymentData = await invoicePaymentData(invoiceId);
        commit("receiveInvoicePaymentData", paymentData);
        dispatch("requestInitPayment");
        // dispatch("prefetchGooglePay");
        if (paymentData.status === "payment") {
          setTimeout(() => {
            dispatch("checkInvoiceStatus", { invoiceId });
          }, INVOICE_STATUS_CHECK_INTERNAL);
        }
      } catch (error) {
        commit("setError", { error });
        commit("setLoading", false, { root: true });
      }
    },

    async [REQUEST_ORDER](
      { commit, dispatch }: ActionContext<State, any>,
      { cardBlock, payMethod }: { cardBlock: object; payMethod: string }
    ) {
      const data = await sendOrder({ cardBlock, payMethod });
      // const data = await require("@/api/stub/tickets-success.json");
      // const data = await require("@/api/stub/all_not_available.json");
      // const data = await require("@/api/stub/item_not_available.json");
      commit("receive", data);
      if (data.status === "processing") {
        switch (payMethod) {
          case "apay": {
            await dispatch("startApplePay");
            break;
          }
          case "gpay": {
            await dispatch("startGooglePay");
            break;
          }
          case "sbp": {
            await dispatch("requestPaySbp");
            break;
          }
          case "card": {
            await dispatch("requestPaymentCard", { status: "NEW" });
            break;
          }
        }
      } else if (data.status === "error") {
        throw data.error;
      }
    },

    async getInvoice({ commit }: ActionContext<State, any>) {
      const { data } = await axios.post("");
    },

    async checkInvoiceStatus(
      { commit, dispatch }: ActionContext<State, any>,
      { invoiceId }: { invoiceId: string }
    ) {
      try {
        const { status } = await invoicePaymentData(invoiceId);
        if (status === "success" || status === "payment_error") {
          commit("setSuccess");
          return;
        }
        if (status === "payment_error") {
          commit("setError", new Error(status));
          return;
        }
        setTimeout(() => {
          dispatch("checkInvoiceStatus", { invoiceId });
        }, INVOICE_STATUS_CHECK_INTERNAL);
      } catch (error) {
        console.error(error);
      }
    },

    async requestMerchantPermission(
      { commit, state }: ActionContext<State, any>,
      { result }: { result: "success" | "error" }
    ) {
      commit("setFetching", true, { root: true });
      try {
        const permissionRequestId = state.confirmData
          .permission_request_id as string;
        await merchantPermission({ permissionRequestId, result });
        commit("receiveMerchantPermission", { result });
      } catch (error) {
        commit("setError", { error });
      } finally {
        commit("setFetching", false, { root: true });
      }
    },

    async requestPaySbp({
      commit,
      dispatch,
      state,
      getters,
    }: ActionContext<State, any>) {
      commit("setFetching", true, { root: true });
      try {
        // пока оставляю для отладки

        // commit("receivePaySbp", {
        //   sbpData: { sbp_url: "https://yandex.ru" },
        //   getters,
        // });

        const sbpData = await paymentSpb({
          amount: state.invoicePaymentData.totalAmount,
          internalInvoiceId: state.invoicePaymentData.internal_id as string,
          internalMerchantId: state.invoicePaymentData.merchant_internal_id,
          email: state.email,
        });
        if (!sbpData.sbp_url) {
          throw new Error("sbp_url is undefined");
        }
        commit("receivePaySbp", { sbpData, getters });
        dispatch("checkInvoiceStatus", {
          invoiceId: state.invoicePaymentData.internal_id,
        });
      } catch (error) {
        commit("setError", { error });
      } finally {
        commit("setFetching", false, { root: true });
      }
    },

    // async requestPaySbp({ commit }) {
    //   commit("receivePaySbp", {
    //     link: "https://qr.nspk.ru/AD10004K20UDNBIM9FIBMQL690Q4FJK6?type=02&bank=100000000061&sum=1000&cur=RUB&crc=E387",
    //   });
    // },

    async requestInitPayment({ commit, state }: ActionContext<State, any>) {
      try {
        const { account } = (await paymentInit({
          merchantInternalId: state.invoicePaymentData.merchant_internal_id,
        })) as InitPaymentData;
        commit("receiveInitPayment", { account });
      } catch (error) {
        commit("setError", { error });
      } finally {
        commit("setLoading", false, { root: true });
      }
    },

    async requestPaymentCard(
      { state, commit, dispatch }: ActionContext<State, any>,
      { operationId, status }: { operationId?: number; status: string }
    ) {
      try {
        const paymentCardData = await paymentCard({
          amount: state.invoicePaymentData.totalAmount,
          invoiceInternalId: state.invoicePaymentData.internal_id,
          merchantInternalId: state.invoicePaymentData.merchant_internal_id,
          paymentExternalId: state.paymentCardData.payment_external_id,
          paymentInternalId: state.paymentCardData.payment_internal_id,
          operationId,
          status,
          email: state.email,
        });
        commit("receivePaymentCard", paymentCardData);
        if ((paymentCardData as PaymentCardData).status === "NEW") {
          const options = {
            account: state.initPaymentData.account,
            transactionId: String(state.paymentCardData.payment_internal_id),
            amount: state.paymentCardData.amount,
          };
          const acquireAlias = state.invoicePaymentData.acquirerAlias;
          switch (acquireAlias) {
            case "PAY2PRO":
            case "PAYLER": {
              setTimeout(() => {
                dispatch("checkInvoiceStatus", {
                  invoiceId: state.invoicePaymentData.internal_id,
                });
              }, 5000);
              break;
            }
            case "MONETA": {
              // см. https://docs.payanyway.ru/payment-form-v2/
              //@ts-ignore
              const assistant = new window.Assistant.Builder();
              assistant.build(options, "payment-form");
              // платёж прошёл успешно
              assistant.setOnSuccessCallback((operationId: number) => {
                dispatch("requestPaymentCard", {
                  operationId,
                  status: "PRE-SUCCESS",
                });
                commit("setSuccess");
              });
              // платёж не прошёл
              assistant.setOnFailCallback((operationId: number) => {
                dispatch("requestPaymentCard", {
                  operationId,
                  status: "ERROR",
                });
              });
              // платёж обрабатывается
              assistant.setOnInProgressCallback((operationId: number) => {
                dispatch("requestPaymentCard", {
                  operationId,
                  status: "PROGRESS",
                });
              });
            }
          }
        }
      } catch (error) {
        commit("setError", { error });
      }
    },

    async startApplePay({ state, commit }: ActionContext<State, any>) {
      const amount = state.invoicePaymentData.totalAmount;

      startApplePay({
        amount,
        merchantInternalId: state.invoicePaymentData.merchant_internal_id,
        internalInvoiceId: state.invoicePaymentData.internal_id,
        email: state.email,
        label: state.invoicePaymentData.platformAlias as string,
        callback: async ({ error, paymentInternalId, payment }) => {
          if (error) {
            commit("setError", { error });
            return;
          }
          if (paymentInternalId) {
            commit("setApplepayData", { paymentInternalId });
            return;
          }
          if (!payment) {
            throw new Error("Payment is undefined");
          }
          // проведение платежа через платежный шлюз
          const { status } = (await paymentApplepay({
            amount,
            merchantInternalId: state.invoicePaymentData.merchant_internal_id,
            internalInvoiceId: state.invoicePaymentData.internal_id,
            paymentInternalId: state.applepayData.payment_internal_id,
            paymentExternalId: null,
            email: state.email,
            status: "PROGRESS",
            datagram: JSON.stringify(payment),
          })) as ApplepayProgressData;
          if (status === "SUCCESS") {
            commit("setSuccess");
            return;
          }
          commit("setError", new Error("Не удалось выполнить платеж"));
        },
      });
    },

    async waitForIsReadyToGooglePay({ commit }: ActionContext<State, any>) {
      const isReadyToPay = await getIsReadyToGooglePay();
      commit("setIsReadyToGooglePay", isReadyToPay);
    },

    async prefetchGooglePay() {
      // @ts-ignore
      await prefetchGooglePaymentData({
        merchantId: googlepayMerchantId,
        merchantName: googlepayMerchantName,
      });
    },

    async startGooglePay({ state, commit }: ActionContext<State, any>) {
      const amount = state.invoicePaymentData.totalAmount;
      const gateway =
        gateways[state.invoicePaymentData.acquirerAlias as AcquireAlias];
      if (!gateway) {
        throw new Error(
          `gateway form ${state.invoicePaymentData.acquirerAlias} is not defined`
        );
      }
      startGooglePay(
        {
          gateway,
          gatewayMerchantId: String(state.initPaymentData.account),
          merchantId: googlepayMerchantId,
          merchantName: googlepayMerchantName,
          totalPrice: String(amount),
        },
        async (paymentData) => {
          const token = paymentData.paymentMethodData.tokenizationData.token;

          try {
            const result = (await paymentGooglepay({
              amount,
              merchantInternalId: state.invoicePaymentData.merchant_internal_id,
              internalInvoiceId: state.invoicePaymentData.internal_id,
              paymentInternalId: state.googlepayData.payment_internal_id,
              paymentExternalId: null,
              email: state.email,
              status: "PROGRESS",
              datagram: token,
            })) as GooglepayProgressData;

            return result;
          } catch (error) {
            const message = processAxiosError(error);
            throw new Error(message);
          }
        },
        () => {
          commit("setSuccess");
        },
        (error) => {
          // ничего не делаем
        }
      );

      const { payment_internal_id } = (await paymentGooglepay({
        amount,
        merchantInternalId: state.invoicePaymentData.merchant_internal_id,
        internalInvoiceId: state.invoicePaymentData.internal_id,
        paymentInternalId: state.applepayData.payment_internal_id,
        paymentExternalId: null,
        email: state.email,
        status: "NEW",
      })) as GooglepayNewData;

      commit("setGooglepayData", { paymentInternalId: payment_internal_id });
    },
  },
  mutations: {
    receive(state: State, data: InvoicePaymentData) {
      state.invoicePaymentData = data;
    },

    receiveInvoicePaymentData(state: State, paymentData: InvoicePaymentData) {
      state.invoicePaymentData = { ...paymentData };
    },

    receiveMerchantPermission(state: State, { result }: { result: any }) {
      const url =
        result === "success"
          ? state.confirmData.acceptedReturnUrl
          : state.confirmData.refusedReturnUrl;
      window.location.replace(url as string);
    },

    receiveInitPayment(state: State, { account }: { account: number }) {
      state.initPaymentData = {
        account,
      };
    },

    receivePaySbp(
      state: State,
      { sbpData, getters }: { sbpData: PaymentSbpData; getters: any }
    ) {
      state.sbpData = { ...sbpData };
      if (getters.isDesktop) {
        state.showSbpQrcode = true;
        QRCode.toCanvas(
          document.getElementById("sbp-qrcode"),
          sbpData.sbp_url,
          (err) => {
            if (err) {
              console.error(err);
            }
          }
        );
        return;
      }
      // @ts-ignore
      window.slideUpWidget.openWidget(sbpData.sbp_url);
    },

    receivePaymentCard(state: State, paymentCardData: PaymentCardData) {
      state.paymentCardData = paymentCardData;
    },

    setApplepayData(
      state: State,
      {
        paymentInternalId,
      }: { paymentInternalId: ApplepayNewData["payment_internal_id"] }
    ) {
      state.applepayData.payment_internal_id = paymentInternalId;
    },

    setGooglepayData(
      state: State,
      {
        paymentInternalId,
      }: { paymentInternalId: GooglepayNewData["payment_internal_id"] }
    ) {
      state.googlepayData.payment_internal_id = paymentInternalId;
    },

    setIsReadyToGooglePay(state: State, isReadyToPay: boolean) {
      state.googlepayData.isReadyToPay = isReadyToPay;
    },

    setConfirmData(
      state: State,
      {
        permission_request_id,
        merchantId,
        acceptedReturnUrl,
        refusedReturnUrl,
      }: State["confirmData"]
    ) {
      state.confirmData.permission_request_id = permission_request_id;
      state.confirmData.merchantId = merchantId;
      state.confirmData.acceptedReturnUrl = acceptedReturnUrl;
      state.confirmData.refusedReturnUrl = refusedReturnUrl;
    },

    setError(state: State, { error }: { error: Error }) {
      const message = processAxiosError(error);
      console.error(message);
      state.error = message;
    },

    setSuccess(state: State) {
      state.invoicePaymentData.status = "success";
    },

    setEmail(state: State, { email }: { email: string }) {
      state.email = email;
    },
  },
};
