import axios from "axios";

const isDevelopment = process.env.NODE_ENV === "development";

export const cfg = {
  baseURL: isDevelopment
    ? process.env.VUE_APP_FNS_API
    : `https://fns-service-api.${window.location.host}`,
  headers: {},
};

export type PaymentMethod = "card" | "sbp" | "applepay" | "googlepay";

export type AcquireAlias = "MONETA" | "PAY2PRO" | "PAYLER";

export interface InvoicePaymentData {
  id: null | string;
  invoiceNumber: null | number | string;
  merchantId: null | string;
  acquirerId: null | string;
  customerType: null | string;
  totalAmount: number;
  saleInfo: any[];
  internal_id: string;
  status: null | string;
  date: null | string;
  merchantName: null | string;
  merchant_internal_id: number;
  inn: null | string;
  listPaymentMethods: null | PaymentMethod[];
  acquirerAlias: null | AcquireAlias;
  platformAlias: null | string; // applepay: внутри paymentRequest нужно заменить label на platformAlias
}

export const invoicePaymentData = async (invoiceId: string | number) => {
  const result = await axios.get<InvoicePaymentData>(
    `/invoice/${invoiceId}/payment_data`,
    cfg
  );
  return result.data;
};

export interface MerchantPermissionData {
  result: "success" | "error";
}

export const merchantPermission = async ({
  permissionRequestId,
  result,
}: {
  permissionRequestId: string;
  result: "success" | "error";
}) => {
  await axios.post<MerchantPermissionData>(
    `/merchant/acquirer/permission/${permissionRequestId}`,
    { result },
    cfg
  );
  return true;
};

export interface ApplepayNewData {
  session: any;
  payment_internal_id: string;
}

export interface ApplepayProgressData {
  status: "SUCCESS" | "ERROR";
  payment_internal_id: string;
}

export const paymentApplepay = async ({
  amount,
  merchantInternalId,
  internalInvoiceId,
  paymentInternalId,
  paymentExternalId,
  status,
  email,
  datagram,
  validationURL,
}: {
  amount: number;
  merchantInternalId: number;
  internalInvoiceId: string;
  status: "NEW" | "PROGRESS";
  paymentInternalId: null | string;
  paymentExternalId: null | number;
  email: string;
  datagram?: string;
  validationURL?: string;
}) => {
  const data: {
    amount: number;
    merchant_internal_id: number;
    internal_invoice_id: string;
    status: "NEW" | "PROGRESS";
    payment_internal_id: null | string;
    payment_external_id: null | number;
    email: string;
    datagram?: string;
    validation_url?: string;
  } = {
    amount,
    merchant_internal_id: merchantInternalId,
    internal_invoice_id: internalInvoiceId,
    status,
    payment_internal_id: paymentInternalId,
    payment_external_id: paymentExternalId,
    email,
  };
  if (datagram) {
    data.datagram = datagram;
  }
  if (validationURL) {
    data.validation_url = validationURL;
  }
  const result = await axios.post<ApplepayNewData | ApplepayProgressData>(
    "/payment/applepay",
    data,
    cfg
  );
  return result.data;
};

export interface GooglepayNewData {
  payment_internal_id: string;
}

export interface GooglepayProgressData {
  status: "SUCCESS" | "ERROR";
  payment_internal_id: string;
}

export const paymentGooglepay = async ({
  amount,
  merchantInternalId,
  internalInvoiceId,
  paymentInternalId,
  paymentExternalId,
  status,
  email,
  datagram,
}: {
  amount: number;
  merchantInternalId: number;
  internalInvoiceId: string;
  status: "NEW" | "PROGRESS";
  paymentInternalId: null | string;
  paymentExternalId: null | number;
  email: string;
  datagram?: string;
}) => {
  const data: {
    amount: number;
    merchant_internal_id: number;
    internal_invoice_id: string;
    status: "NEW" | "PROGRESS";
    payment_internal_id: null | string;
    payment_external_id: null | number;
    email: string;
    datagram?: string;
  } = {
    amount,
    merchant_internal_id: merchantInternalId,
    internal_invoice_id: internalInvoiceId,
    status,
    payment_internal_id: paymentInternalId,
    payment_external_id: paymentExternalId,
    email,
  };
  if (datagram) {
    data.datagram = datagram;
  }
  const result = await axios.post<GooglepayNewData | GooglepayProgressData>(
    "/payment/googlepay",
    data,
    cfg
  );
  return result.data;
};

export interface InitPaymentData {
  account: number;
}

export const paymentInit = async ({
  merchantInternalId,
}: {
  merchantInternalId: number;
}) => {
  const data = {
    merchant_internal_id: merchantInternalId,
  };
  const result = await axios.post<InitPaymentData>("/payment/init ", data, cfg);
  return result.data;
};

export interface PaymentSbpData {
  payment_internal_id: string;
  sbp_url: string;
}

export const paymentSpb = async ({
  amount,
  internalInvoiceId,
  internalMerchantId,
  email,
}: {
  amount: number;
  internalInvoiceId: string;
  internalMerchantId: number;
  email: string;
}) => {
  const data = {
    amount,
    internal_invoice_id: internalInvoiceId,
    internal_merchant_id: internalMerchantId,
    email,
    status: "NEW",
  };
  const result = await axios.post<PaymentSbpData>("/payment/sbp", data, cfg);
  return result.data;
};

export interface PaymentCardData {
  amount: number;
  invoice_internal_id: string;
  merchant_internal_id: string;
  email: string;
  payment_internal_id: string;
  payment_external_id: string;
  operation_id?: number;
  status: string;
  paymentUrl?: string;
}

export const paymentCard = async ({
  amount,
  invoiceInternalId,
  merchantInternalId,
  operationId,
  paymentExternalId,
  paymentInternalId,
  email,
  status,
}: {
  amount: number;
  invoiceInternalId: string;
  merchantInternalId: number;
  operationId?: number;
  paymentInternalId?: string;
  paymentExternalId?: string;
  email: string;
  status: string;
}) => {
  const data: {
    amount: number;
    invoice_internal_id: string;
    merchant_internal_id: number;
    operation_id?: number;
    email: string;
    payment_internal_id?: string;
    payment_external_id?: string;
    status: string;
  } = {
    amount,
    invoice_internal_id: invoiceInternalId,
    merchant_internal_id: merchantInternalId,
    email,
    status,
  };
  if (operationId) {
    data.operation_id = operationId;
  }
  if (paymentExternalId) {
    data.payment_internal_id = paymentExternalId;
  }
  if (paymentInternalId) {
    data.payment_internal_id = paymentInternalId;
  }
  const result = await axios.post<PaymentCardData>("/payment/card", data, cfg);
  if (isDevelopment) {
    console.debug(`/payment/card: ${result}`);
  }
  return result.data;
};

export interface CardBindData {
  bind_url: string;
}

export const requestCardBind = async (
  internalId: string
): Promise<CardBindData> => {
  const { data } = await axios.post<CardBindData>(
    "/payout/card/bind",
    { internal_id: internalId },
    cfg
  );
  return data;
};

export interface CardBindStatusData {
  merchant_id: string;
  acquirer_id: string;
  status: "SUCCESS" | "ERROR" | string;
}

export const requestCardBindStatus = async (
  internalId: string
): Promise<CardBindStatusData> => {
  const { data } = await axios.get<CardBindStatusData>(
    `/payout/card/bind/${internalId}`,
    cfg
  );
  return data;
};
