export const SET_LANG = "setLang";
export const SET_VALUE = "setValue";
export const RECEIVE_PRODUCTS = "receiveProducts";
export const VALIDATE = "validate";
export const CLEAR = "clear";
export const MENU_INIT = "menuInit";
export const MENU_ADD = "menuAdd";
export const MENU_REMOVE = "menuRemove";
export const ITEMS_INIT = "itemsInit";
export const ITEMS_ADD = "itemsAdd";
export const ITEMS_REMOVE = "itemsRemove";
export const PRODUCT_ADD = "productAdd";
export const PRODUCT_REMOVE = "productRemove";
export const MENU_CLEAR = "menuClear";
export const ITEMS_CLEAR = "itemsClear";
export const SET_LOADING = "setLoading";
export const SET_FETCHING = "setFetching";
export const RECEIVE_PAGE = "receivePage";
export const SET_TAG = "setTag";
export const FEEDBACK_CHANGE = "feedbackInit";
export const CARD_BLOCK_CHANGE = "cardBlockChange";
export const FEEDBACK_EMPTY_CHANGE = "feedbackEmptyChange";
export const SET_INVOICE = "setCheckoutResult";
export const SET_CASE_NAME = "setCaseName";
export const PAGE_TEMPLATE_INIT = "pageTemplateInit";
export const TIPS_BUTTONS_INIT = "tipsButtonsInit";
export const SET_ROUTE = "setRoute";
export const CHANGE_TOTAL = "changeTotal";
export const CHANGE_TOTAL_ERROR = "changeTotalError";
export const TIPS_BUTTONS_CHANGE = "tipsButtonsChange";
export const TICKETS_EVENT_INIT = "ticketsEventInit";
export const TICKETS_LIST_INIT = "ticketsListInit";
