import {
  MENU_INIT,
  MENU_ADD,
  MENU_REMOVE,
  SET_TAG,
  MENU_CLEAR,
  PAGE_TEMPLATE_INIT,
  ITEMS_INIT,
  ITEMS_ADD,
  ITEMS_REMOVE,
  // ITEMS_CLEAR,
} from "../mutations.type";
import { Block } from "@/constants";

export const getBlock = (page, pageName, blockName) =>
  page?.pages
    .find((page) => page.name === pageName)
    ?.blocks.find((b) => b.name === blockName) || null;

const localeProducts = (page) => {
  const products = [];
  Object.keys(localStorage)
    .filter((item) => item.split("_")[0] === page)
    .forEach((product) =>
      products.push(JSON.parse(localStorage.getItem(product)))
    );

  return products;
};

const updateLocaleProduct = (product, count, page) => {
  count
    ? localStorage.setItem(
        `${page}_${product.id}`,
        JSON.stringify({
          ...product,
          count,
        })
      )
    : localStorage.removeItem(`${page}_${product.id}`);
};

const localeItemsProducts = (page) => {
  const products = [];
  Object.keys(localStorage)
    .filter((item) => item.split("_")[0] === page)
    .forEach((product) => {
      products.push(JSON.parse(localStorage.getItem(product)));
    });
  return products;
}; //Для AvaliableItemsBlock

const updateLocaleItemsProduct = (product, count, page, index) => {
  product.prices[index]["name"] = product.name;
  count
    ? localStorage.setItem(
        `${page}_${product.id}_${index}`,
        JSON.stringify({
          ...product.prices[index],
          count,
        })
      )
    : localStorage.removeItem(`${page}_${product.id}`);
}; //Для AvaliableItemsBlock

export default {
  state: () => ({
    tag: null,
    qty: {},
    total: 0,
    page: null,
    pageTemplate: null,
  }),
  getters: {
    menuBlock: (state, getters, rootState) =>
      getBlock(rootState.page, rootState.route.main, Block.AvailableMenuBlock),
    itemsBlock: (state, getters, rootState) =>
      getBlock(rootState.page, rootState.route.main, Block.AvailableItemsBlock),
    total: (state) => state.total,
    pagePath: (state) => state.page,
    pageTemplate: (state) => state.pageTemplate,
  },
  mutations: {
    [MENU_INIT](state, { block, page, template }) {
      state.qty = {};
      state.total = 0;
      if (!Object.keys(state.qty).length) {
        state.page = page;
        state.pageTemplate = template;
        const items = block.body.find((b) => b.name === "items").value;
        for (let item of items) {
          const name = item.name;
          state.qty[name] =
            localeProducts(state.page).find((product) => product.name === name)
              ?.count || 0;
          state.total +=
            state.qty[name] *
              localeProducts(state.page).find(
                (product) => product.name === name
              )?.price || 0;
        }
      }
    },
    [MENU_ADD](state, product) {
      const { name, price } = product;
      state.qty = { ...state.qty, [name]: state.qty[name] + 1 };
      state.total += price;
      updateLocaleProduct(product, state.qty[name], state.page);
      state.totalError = !state.total;
    },
    [ITEMS_INIT](state, { block, page, template }) {
      state.qty = {};
      state.total = 0;
      if (!Object.keys(state.qty).length) {
        state.page = page;
        state.pageTemplate = template;
        const items = block.body.find((b) => b.name === "items").value;
        for (let item of items) {
          const name = item.name;
          state.qty[name] = [];
          for (let i = 0; i < item.prices.length; i++) {
            let configuration = name + " " + item.prices[i].description;
            state.qty[name].push(
              localeItemsProducts(state.page).find(
                (product) =>
                  product.name + " " + product.description === configuration
              )?.count || 0
            );

            state.total +=
              state.qty[name][i] *
                localeItemsProducts(state.page).find(
                  (product) =>
                    product.name + " " + product.description === configuration
                )?.price || 0;
          }
        }
      }
    },
    [ITEMS_ADD](state, productData) {
      let index = productData.index,
        name = productData.product.name,
        price = productData.product.prices[index].price;
      state.qty[name][index] += 1;
      state.total += price;
      updateLocaleItemsProduct(
        productData.product,
        state.qty[name][index],
        state.page,
        index
      );
      state.totalError = !state.total;
    },
    [MENU_REMOVE](state, product) {
      const { name, price } = product;
      state.qty = { ...state.qty, [name]: state.qty[name] - 1 };
      state.total -= price;
      updateLocaleProduct(product, state.qty[name], state.page);
    },
    [ITEMS_REMOVE](state, productData) {
      let index = productData.index,
        name = productData.product.name,
        price = productData.product.prices[index].price;
      state.qty[name][index] -= 1;
      state.total -= price;
      updateLocaleProduct(
        productData.product,
        state.qty[name][index],
        state.page,
        index
      );
    },
    [MENU_CLEAR](state) {
      for (const key in state.qty) {
        state.qty[key] = 0;
      }
      state.total = 0;
    },
    [SET_TAG]: (state, tag) => (state.tag = tag),
    [PAGE_TEMPLATE_INIT]: (state, data) => (state.pageTemplate = data),
  },
};
