<template>
  <section class="footer">
    <div class="footer__description">
      Без комиссии. Безопасность платежей обеспечена технологией Super Security
    </div>
    <div class="footer__offer">
      <router-link to="/">Пользовательское соглашение</router-link> и
      <router-link to="/">политика конфиденциальности</router-link>
    </div>
    <div class="footer__pay-services">
      <div
        v-for="ps in payServices"
        :key="ps"
        class="footer__pay-service"
        :class="'footer__pay-service_' + ps"
      >
        <img :src="require(`@/assets/img/${ps}.svg`)" :alt="`${ps} icon`" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    payServices: ["visa", "mastercard", "maestro", "mir"],
  }),
};
</script>

<style lang="scss">
.footer {
  padding: 16px 15px 0;
  font-family: "Open Sans", sans-serif;

  &__description {
    font-size: 21px;
    line-height: 29px;
  }

  &__offer {
    margin: 9px 0 21px;
    font-size: 16px;
    color: #909192;

    a {
      text-decoration-line: underline;
      color: #909192 !important;
    }
  }

  &__pay-services {
    padding: 22px 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__pay-service {
    margin-right: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-of-type {
      margin-right: 0;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    &_visa {
      width: 45px;
    }

    &_mastercard {
      width: 41px;
    }

    &_maestro {
      width: 41px;
    }

    &_mir {
      width: 49px;
    }
  }
}
</style>
