export const Block = Object.freeze({
  HeaderBlock: "HeaderBlock",
  MerchantBlock: "MerchantBlock",
  AvailableMenuBlock: "AvailableMenuBlock",
  AvailableItemsBlock: "AvailableItemsBlock",
  CartBlock: "CartBlock",
  FeedbackBlock: "FeedbackBlock",
  PaymentMethodsBlock: "PaymentMethodsBlock",
  BillDeliveryBlock: "BillDeliveryBlock",
  ResultTextBlock: "ResultTextBlock",
  OrderNumberBlock: "OrderNumberBlock",
  MerchantContactsBlock: "MerchantContactsBlock",
  RetryButtonBlock: "RetryButtonBlock",
  EventBlock: "EventBlock",
});

export const masks = {
  phone: {
    ru: [
      "+",
      "7",
      " ",
      "(",
      /[1-9]/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
    ],
  },
};
