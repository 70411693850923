import axios, { AxiosError } from "axios";

export function processAxiosError(error: Error | AxiosError): string {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      const { data } = error.response;
      // ингда такой ответ
      // например, если не найден path на сервере
      if (data.detail) {
        return data.detail;
      }
      // такой ответ, если не найден, например, счет
      const { error: err } = error.response.data;
      const { code, desc } = err;
      return `${code}: ${desc}`;
    }
    if (error.request) {
      console.log(error.request);
    }
    return error.message;
  } else {
    return error.message;
  }
}
