import axios from "axios";
import { invoicePaymentData } from "@/api/fns";
import { cfg } from "./fns";

export interface Body {
  name: string;
  value: any;
  required: boolean;
}

export interface Meta {
  constructor_editable: boolean;
  page_editable: boolean;
}

export interface Block {
  name: string;
  body: Body[];
  meta: Meta;
}

export interface Page {
  name: string;
  blocks: Block[];
}

export interface ITemplateData {
  name: string;
  case: string;
  pages: Page[];
}

export interface CheckoutResult {
  acquirerAlias: string;
  acquirerId: string;
  customerType: string;
  date: string;
  error: string;
  id: string;
  inn: string;
  internal_id: string;
  invoiceNumber: number;
  merchantId: string;
  merchantName: string;
  merchant_internal_id: number;
  platformAlias: string;
  platform_id: null | number | string;
  saleInfo: any;
  states: string;
  totalAmount: number;
}

export const getTemplate = async (): Promise<ITemplateData> => {
  try {
    const templateId = JSON.parse(<string>localStorage.getItem("templateId"));
    if (templateId === "tickets") {
      const data = await require("@/api/stub/tickets.json");
      return data as ITemplateData;
    } else if (templateId === "testbani") {
      const data = await require("@/api/stub/test.json");
      return data as ITemplateData;
    } else {
      const { data } = await axios.get(`/pages/${templateId}`, cfg);
      return data as ITemplateData;
    }
  } catch (err: any) {
    if (err.response?.data) {
      const error = err.response.data.detail || err.response?.data?.error?.desc;
      throw new Error(error);
    }
    throw err;
  }
};

export const sendOrder = async ({
  cardBlock,
  payMethod,
}: {
  cardBlock: object;
  payMethod: string;
}) => {
  try {
    // @ts-ignore
    const { template_id } = cardBlock;
    // @ts-ignore
    if (cardBlock.case == "fix") {
      const internal_id = localStorage.getItem("internal_id");
      //@ts-ignore
      const data = await invoicePaymentData(internal_id);

      return data;
    }
    const { data } = await axios.post(
      `/pages/${template_id}/invoices${
        payMethod === "order" ? "?no_pay=true" : ""
      }`,
      cardBlock,
      cfg
    );
    return data;
  } catch (err: any) {
    if (err.response?.data) {
      const error = err.response.data.detail || err.response?.data?.error?.desc;
      throw new Error(error);
    }
    throw err;
  }
};

export const postInvoiceNumber = async (data: Object) => {
  const templateId = JSON.parse(<string>localStorage.getItem("templateId"));
  const invoice = await axios.post(`/pages/${templateId}/invoices`, data, cfg);
  return invoice;
};

export const getInvoice = async (
  invoice_id: string
): Promise<CheckoutResult> => {
  try {
    const { data } = await axios.get(
      `/invoice/${invoice_id}/payment_data`,
      cfg
    );
    return data as CheckoutResult;
  } catch (err: any) {
    if (err.response?.data) {
      const error = err.response.data.detail || err.response?.data?.error?.desc;
      throw new Error(error);
    }
    throw err;
  }
};
