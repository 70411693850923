import { mapGetters, mapMutations, mapState } from "vuex";
import { SET_LANG } from "@/store/mutations.type";

export default {
  name: "Header",
  data: () => ({
    langSelectorVisible: false,
  }),
  computed: {
    ...mapState({ lang: (state) => state.header.lang }),
    ...mapGetters(["langs"]),
  },
  methods: {
    ...mapMutations({ setLang: SET_LANG }),
    selectLang(e) {
      const lang = e.target.value;
      this.toggleLangs();
      this.setLang({ lang });
    },
    toggleLangs() {
      this.langSelectorVisible = !this.langSelectorVisible;
    },
  },
};
