<template>
  <v-app>
    <app-wrap>
      <preloader :isLoading="isLoading" :isFetching="isFetching" />
      <router-view />
    </app-wrap>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Preloader from "./components/fns/Preloader.vue";

export default {
  components: { Preloader },
  computed: {
    ...mapState(["isLoading", "isFetching"]),
  },
};
</script>

<style lang="scss">
@import "@/assets/fonts/Inter";
@import "@/assets/fonts/JetBrainsMono";
@import "@/assets/scss/variables";
@import "@/assets/scss/fns/index";
</style>
