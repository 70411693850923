import { Block } from "@/constants";
import { GET_INVOICE } from "@/store/actions.type";
import {
  FEEDBACK_CHANGE,
  CARD_BLOCK_CHANGE,
  FEEDBACK_EMPTY_CHANGE,
  SET_INVOICE,
} from "../mutations.type";
import { getBlock } from "@/store/modules/menu";
import { getInvoice } from "@/api/template";

export default {
  state: {
    feedback: [],
    cardBlock: null,
    feedBackNotEmpty: true,
    invoice: null,
  },
  mutations: {
    [FEEDBACK_CHANGE]: (state, data) => (state.feedback = data),
    [CARD_BLOCK_CHANGE]: (state, data) => (state.cardBlock = data),
    [FEEDBACK_EMPTY_CHANGE]: (state, data) => (state.feedBackNotEmpty = data),
    [SET_INVOICE]: (state, data) => (state.invoice = data),
  },
  getters: {
    feedbackBlock: (state, getters, { page, route }) =>
      getBlock(page, route.current, Block.FeedbackBlock),
    paymentMethods: (state, getters, { page, route }) =>
      getBlock(page, route.current, Block.PaymentMethodsBlock),
    paymentBlock: (state, getters, { page, route }) =>
      getBlock(page, route.main, Block.CartBlock),
    feedbackValue: (state) => state.feedback,
    feedBackNotEmpty: (state) => state.feedBackNotEmpty,
    invoiceData: (state) => state.invoice,
  },
  actions: {
    async [GET_INVOICE]({ commit }, invoice_id) {
      const data = await getInvoice(invoice_id);
      commit(SET_INVOICE, data);
    },
  },
};
